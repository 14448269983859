<template>
    <div class="overlay w100 h100 abs">
        <div class="overlay-panel rel">
        <div class="panel-header p24">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Reset Password</h2>
        </div>
        <form class="forgotpass" @submit.prevent="changePassword"> 
            <div class="pl24 pr24 pb0">
              <p>Modifica Password</p>
              <div class="flex-container mb24">
                <div class="flex-container flex-col mb24">
                    <label class="flex-container flex-col pb8">
                        <div class="flex-container heading-and-error">
                            <h3 class="bebas nomargin">Inserisci nuova password</h3>
                            <ErrorMessage :validationStatus="vuel.password" />
                        </div>
                        
                        <input 
                            required 
                            v-model="password" 
                            type="password" 
                            placeholder="inserisci la nuova password"
                            @blur="handleBlur('password')"
                            class="noborder nopadding pl8"
                        />
                    </label>
                    <label class="flex-container flex-col">
                        <div class="flex-container heading-and-error">
                            <h3 class="bebas nomargin">Conferma Password</h3>
                            <ErrorMessage :validationStatus="vuel.passwordConfirmation" />
                        </div>
                        
                        <input 
                            required 
                            v-model="passwordConfirmation" 
                            type="password" 
                            placeholder="conferma password"
                            @blur="handleBlur('passwordConfirmation')"
                            class="noborder nopadding pl8"
                        />
                    </label>
                </div>
              </div>
            </div>
            
            <div class="flex-container ">
                <input :disabled="password === null || password.length < 8 || password !== passwordConfirmation" type="submit" value="Submit" class="w100 noborder nopadding bebas">
            </div>
        </form>
    </div> 
    </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import useVuelidate from "@vuelidate/core";
import { required,minLength, sameAs } from "@vuelidate/validators";
import {  MESSAGE } from '@/store/VuexPaths'
import ErrorMessage from "@/components/formelements/ErrorMessage";

export default {
  components:{
    ErrorMessage
  },
  setup (){
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    
    const password = ref(null);
    const passwordConfirmation = ref(null)
    // Validation Logics
    const rules = {
      password: { required, minLength: minLength(8) },
      passwordConfirmation: sameAs( ()=> { return password })
      
    };
    const vuel = useVuelidate(
      rules,
      { password , passwordConfirmation}
    );
    const handleBlur = (key) =>{
      
      vuel.value[key].$dirty = true;
    };

    const changePassword = async () => {
      
      vuel.$dirty = true;
      if (!vuel.$error) {
        try {
          await store.dispatch('auth/changePassword', { 
              password:password.value, 
              passwordConfirmation:passwordConfirmation.value,
              token:route.params.token
          })
          await store.dispatch(MESSAGE, { title:'OK', message:'password cambiata'})
         
          router.push('/login')
        } catch(err) {
          await store.dispatch(MESSAGE, { title:'errore nella modifica password', message:err.message })
          console.log(err.message)
          console.log(err)
        }
      }
      
    }

    

    return{
      password,  
      passwordConfirmation,
      vuel,
      handleBlur,
      changePassword
    }
  }
}
</script>